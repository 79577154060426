<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class=" box-flex">
      <div class="container review-main text-capitalize position-relative  box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card | d-flex flex-column ">
              <div class="center-card-box top-sticky border-bottom">
                <div class="resturant-details p-3">
                  <h4 class>{{ $t('Add Money to Wallet') }}</h4>
                </div>
              </div>
              <div class="center-card-box">
                <div class="p-3">
                  <form class="boxed payment-box">
                    <div class="wallet-boxs">
                      <span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>
                      <input class="form-control pay-amount mb-0 h50" type="text" :placeholder="placeholder" id="amount"
                        name="amount" v-model="amount"
                        oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                        minlength="1" maxlength="5" />
                    </div>
                    <!-----skeleton-loader--------->
                    <div class="d-block position-relative mt-2" v-for="i in 4" :key="i" v-if="isSkeletonLoader">
                      <label for="payonline0">
                        <span><skeleton-loader-vue type="string" width="150px" height="20px" /></span>
                      </label>
                    </div>
                    <!-----skeleton-loader--------->
                    <!-- <div v-if="payment_gateway_list && payment_gateway_list.length > 0"> -->
                    <div class="d-block position-relative mt-2" v-for="(pay, index) in payment_gateway_list" :key="index"
                      v-if="isPayOnlineEnable && !isSkeletonLoader">
                      <input type="radio" :id="'payonline' + index" name="payusing" :value="pay.gatewayname"
                        v-model="payusing"
                        v-if="onlinePaymentEnableOption.includes(pay.gatewayname) && pay.is_webview == '1' && pay.walletrechargeurl != ''" />
                      <label :for="'payonline' + index"
                        v-if="onlinePaymentEnableOption.includes(pay.gatewayname) && pay.is_webview == '1' && pay.walletrechargeurl != ''">
                        <div class="card-cls">
                          <img src="@/assets/images/card2.svg" />
                        </div>
                        <span>{{ $t('Add Money Via pay', { msg: pay.gatewayname }) }}</span>
                      </label>
                    </div>
                    <!-- </div> -->
                    <div v-else class="order-offers">
                      <p>{{ $t('No Payment Methods') }}</p>
                    </div>
                  </form>
                </div>
              </div>
              <div class="center-card-box | mt-auto bottom-sticky">
                <div v-if="isPayOnlineEnable">
                  <div class="full-btn green-btn">
                    <b-button variant="primary" class="nxt-btn" disabled v-if="isDisabled">
                      <b-spinner small type="grow"></b-spinner>{{ $t('Loading...') }}</b-button>
                    <button v-else @click="payRecharge" :disabled='isDisabled'>
                      {{ (isDisabled) ? $t('Add') + '...' : $t('Add Money').toUpperCase() }}
                    </button>
                  </div>
                </div>
                <track-order v-if="isOrderSuccess" :orderId="placeOrderId" :iswallet="iswallet"></track-order>
                <fail-order v-if="isOrderfail"></fail-order>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--Alert Modal start-->
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <!--Alert Modal End-->
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import FailOrder from '../components/FailOrder.vue';
import AlertModal from '../components/AlertModal.vue';
import Wallet from '../apis/wallet';
import Footer from "../components/Footer.vue";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  components: {
    NavBar,
    FailOrder,
    AlertModal,
    Footer,
    'skeleton-loader-vue': VueSkeletonLoader
  },
  data() {
    return {
      // onlinePaymentEnableOption: ['razorpay', 'paypal', 'stripe', 'omise', 'yoco', 'sslcommerz', 'peach-web', 'paytm', 'payumoney', 'pesapal', 'paystack', 'cashfree'], //Only this payment gateway are will be enable for online payment
      onlinePaymentEnableOption: [], //Only this payment gateway are will be enable for online payment
      isOrderfail: false, //Fail order popup
      isOrderSuccess: false, //Success order popup
      payusing: "", //Customer's selected payment gateway
      payment_gateway_list: [], //Admin payment gateway list
      isPayOnlineEnable: false, //Check online payment
      placeOrderId: 0, //Place orderid, it get used when need to redirect to track screen
      isDisabled: false, //Used to enable/disable place order button, also for loading
      loading: true,
      isAlert: false,
      AlertData: {},
      amount: '',
      iswallet: 1,
      placeholder: '0.0',
      isSkeletonLoader: true,
    }
  },
  mounted() {
    // if (!this.$auth.user) {
    //   this.$router.push({ name: "home" });
    //   return false;
    // }
    //Check customer login
    setTimeout(() => {
      //Get admin setting
      if (this.$auth.setting) {
        this.onlinePaymentEnableOption = this.$auth.setting.payment_gateway_new;
        if (this.$auth.setting && this.$auth.setting.payment_gateway_list.length > 0) {
          this.isPayOnlineEnable = true;
          this.payment_gateway_list = this.$auth.setting.payment_gateway_list;
          this.isSkeletonLoader = false;
        } else {
          this.isSkeletonLoader = false;
        }
      }
      let routeQry = this.$route.query;
      if (routeQry.success) {
        this.isOrderSuccess = true;
      } else if (routeQry.fail) {
        this.isOrderfail = true;
      }
      if (this.$auth && !this.$auth.user) {
        this.$router.push({ name: "sign-in" });
      }
    }, 1000);

  },

  methods: {
    onModalCloseFun(value) {
      this.isAlert = false;
    },
    //Check order, update payment method and type to place order
    payRecharge() {
      if (this.amount == '' || this.amount == 0) {
        this.$toasted.error(this.$t('Please Add Amount!'), { duration: 2000 });
      } else {
        if (this.payusing != '') {
          if (Number(this.amount) >= Number(this.$auth.setting.wallet_recharge_limit)) {
            this.AlertData = {
              displayMessage: this.$t('Wallet Recharge Limit exceeded'),
              isSetClass: 'paymnt-modal-cls'
            };
            this.isAlert = true;
            window.$(this.$refs.AlertModal).modal();
          } else {
            this.placeWalletRecharge();
          }
        } else {
          this.AlertData = {
            displayMessage: this.$t('Please select any payment option to pay'),
            isSetClass: 'paymnt-modal-cls'
          };
          this.isAlert = true;
          window.$(this.$refs.AlertModal).modal();
        }
      }

    },

    placeWalletRecharge() {
      this.isDisabled = true;
      if (this.payusing == 'razorpay') {
        this.payUsingGatway('razorpayRechargeWalletApi');
      } else if (this.payusing == 'paypal') {
        this.payUsingGatway('paypalRechargeWalletApi');
      } else if (this.payusing == 'stripe') {
        this.payUsingGatway('stripeRechargewalletApi');
      } else if (this.payusing == 'yoco') {
        this.payUsingGatway('yocoRechargewalletApi');
      } else if (this.payusing == 'sslcommerz') {
        this.payUsingGatway('sslcommerzRechargewalletApi');
      } else if (this.payusing == 'peach-web') {
        this.payUsingGatway('peachRechargewalletApi');
      } else if (this.payusing == 'paytm') {
        this.payUsingGatway('paytmRechargeWalletApi');
      } else if (this.payusing == 'payumoney') {
        this.payUsingGatway('payuRechargeWalletApi');
      } else if (this.payusing == 'paystack') {
        this.payUsingGatway('paystackRechargeWalletApi');
      } else if (this.payusing == 'cashfree') {
        this.payUsingGatway('getCashfreeRechargeWallet');
      } else if (this.payusing == 'flow') {
        this.payUsingGatway('getFlowRechargeWallet');
      } else if (this.payusing == '4geeks') {
        this.payUsingGatway('geeksRechargeWallet');
      } else if (this.payusing == 'flutterwave') {
        this.payUsingGatway('flutterwaveRechargeWallet');
      }
    },

    payUsingGatway(apiname) {
      let vm = this;
      Wallet[apiname]({
        'user_id': this.$auth.user.user_id,
        'vendor_id': this.$auth.getVendorId(),
        'is_langauge': this.$store.state.lang.locale,
        'recharge_amount': this.amount,
        'isweb': 1
      }).then((response) => {
        if (response.data.code == 200) {
          if (response.data.Result.recharge_link && response.data.Result.recharge_link != '') {
            window.open(response.data.Result.recharge_link, "_self");
          }
        } else {
          this.isDisabled = false;
          this.$toasted.error(response.data.msg, { duration: 2000 });
        }
      }).catch(function () {
        vm.isDisabled = false;
        alert(vm.$t('Something went wrong, Please try again'));
      });
    }
  }
};
</script>
